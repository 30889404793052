body {
  margin: 0;
  box-sizing: border-box;
  direction: rtl;

}

* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');